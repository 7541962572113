import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Story from './components/Story';
import Advantages from './components/Advantages';
import JoinFlock from './components/JoinFlock';
import Faq from './components/Faq';
import ContactAndFooterSection from './components/ContactAndFooterSection';
import FixedButton from './components/FixedButton';
import Mob from './components/Mob'; // Import the Mob component
import './App.css'; // Import the App.css file

function App() {
  // State to track whether the screen is mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 500;
      console.log('Window width:', window.innerWidth, '| isMobile:', isMobileView);
      setIsMobile(isMobileView);
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Perform initial check on mount
    handleResize();

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Dependency array is empty, no `isMobile` needed

  return (
    <div className="App">
      {isMobile ? (
        // Render Mob component for mobile view
        <Mob />
      ) : (
        // Render desktop components
        <>
          <Navbar />
          <div className="content">
            <Hero />
            <Story />
            <Advantages />
            <JoinFlock />
            <Faq />
            <ContactAndFooterSection />
          </div>
          <FixedButton />
        </>
      )}
    </div>
  );
}

export default App;
