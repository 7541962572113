import React from 'react';
import { motion } from 'framer-motion';
import './contactfooter.css';

const Contact = () => {
  return (
    <motion.div
      id="contact"
      initial={{ x: -100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 1 }}
      className="contact"
    >
      <div className="contact-container">
        <h2 className="contact-title">
          LOOKING TO GET INVOLVED?
          <div className="contact-subtitle">CONTACT US</div>
        </h2>

        <form className="contact-form">
          <label htmlFor="name" className="contact-label">YOUR NAME</label>
          <input 
            type="text" 
            id="name"
            placeholder="" 
            className="contact-input"
            required 
          />
          
          <label htmlFor="email" className="contact-label">YOUR EMAIL</label>
          <input 
            type="email" 
            id="email"
            placeholder="" 
            className="contact-input"
            required 
          />
          
          <label htmlFor="query" className="contact-label">YOUR QUERY</label>
          <textarea 
            id="query"
            placeholder="" 
            className="contact-textarea"
            required
          />

<button
  type="submit"
  className="contact-button"
>
  <motion.span
    whileHover={{ scale: 1.1 }}
    transition={{ duration: 0.3 }}
  >
    SUBMIT
  </motion.span>
</button>
        </form>

        <p className="contact-info">
          Support team will contact you as soon after we get any query
          <br />
          Process continuing us? Try connecting to our Community on Discord
        </p>
      </div>
    </motion.div>
  );
};

const Footer = () => {
  return (
    <div className="footer-background">
      <footer className="footer">
        <div className="footer-content">
        <div className="footer-icons">
  <motion.a
    href="https://discord.gg/J2j9bZAm"
    target="_blank"
    rel="noopener noreferrer"
    whileHover={{ scale: 1.1, rotate: 5 }}
    transition={{ duration: 0.3 }}
  >
    <img src="/discord.svg" alt="Discord" className="footer-icon" />
  </motion.a>
  <motion.a
    href="https://x.com/parrottownNFT"
    target="_blank"
    rel="noopener noreferrer"
    whileHover={{ scale: 1.1, rotate: 5 }}
    transition={{ duration: 0.3 }}
  >
    <img src="/twitter.png" alt="Twitter" className="footer-icon" />
  </motion.a>
</div>

          <p className="footer-text">
            All copyrights reserved @ parrottown2024
          </p>
        </div>
      </footer>
    </div>
  );
};

const ContactAndFooterSection = () => {
  return (
    <div className="relative">
      <Contact />
      <Footer />
    </div>
  );
};

export default ContactAndFooterSection;
