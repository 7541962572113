import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./faq.css";

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  useEffect(() => {
    const faqSection = document.getElementById("faq");
    if (faqSection) faqSection.style.scrollMarginTop = "80px"; // Adjust based on navbar height if necessary
  }, []);

  const faqs = [
    { 
      question: "What is Ara's Canopy?", 
      answer: "Ara’s Canopy is a cyberpunk-themed NFT project set in the mystical forest known as the Verdant Veil. The project features unique, augmented parrots who escaped from a laboratory and established their technologically advanced town. Each NFT is a distinct parrot with cyberpunk modifications, and by owning one, you become part of a vibrant community that helps shape the future of this world." 
    },
    { 
      question: "How do I mint a parrot NFT?", 
      answer: "Minting a parrot is simple! Just follow these steps Connect your wallet: Use the Connect to Wallet button on our landing page. We recommend using MetaMask Minting page: Once your wallet is connected, go to the Minting section of the website, select how many parrots you want to mint, and confirm the transaction.Payment: You’ll need to have cryptocurrency (e.g., ETH) in your wallet to cover the minting fee. Make sure your wallet has enough balance for both the mint price and any gas fees.Receive your NFT: Once the transaction is confirmed, your new parrot NFT will appear in your wallet and can be viewed on OpenSea or other NFT platforms." 
    },
    { 
      question: "What blockchain is Ara’s Canopy using?", 
      answer: "Ara’s Canopy operates on the Ethereum blockchain. This ensures that your parrot NFTs are securely stored on the blockchain and tradable on major platforms like OpenSea." 
    },
    { 
      question: "What makes each parrot NFT unique?", 
      answer: "Every parrot in Ara’s Canopy is generated using a combination of traits, such as their feather colors, cyberpunk augmentations, gear, backgrounds, and more. Some parrots have rare features, such as specialized tech gadgets, legendary equipment, or rare color patterns. No two parrots are the same, making each one a unique collectible" 
    },
    { 
      question: "What are the benefits of owning a parrot NFT?", 
      answer: "By owning a parrot NFT, you unlock several exciting perks Community Access: You’ll gain access to the Ara’s Canopy Discord, where you can chat with other parrot holders and participate in exclusive community events.Voting Rights: Holders can vote on future project developments, giving you a say in how the lore and community evolve.Special Airdrops: Periodic surprises in the form of rare items or digital upgrades will be airdropped to loyal NFT holders.Exclusive Events: Gain access to both virtual and real-world events where the community gathers, and new project updates are announced.Check out the Clearance & Advantages section for more information on what each rank of parrot offers!" 
    },
    { 
      question: "Can I sell my parrot NFT?", 
      answer: " Absolutely! Once you mint or purchase a parrot NFT, you own it and can sell it on marketplaces like OpenSea or any other compatible platforms. Simply connect your wallet, list your parrot for sale, and set the price you’d like to sell it for." 
    },
    { 
      question: "How do I join the Ara’s Canopy community?", 
      answer: "Joining the flock is easy Discord After purchasing an NFT, head to our Discord channel (linked in the Community section) and verify your ownership to gain full access to exclusive chats and events.Twitter Follow us on Twitter for real-time updates, announcements, and fun community interactions." 
    },
    { 
      question: "What wallets are compatible with Ara’s Canopy NFTs", 
      answer: "The recommended wallets include MetaMask (for Ethereum-based NFTs) Phantom (for Solana NFTs, if applicable) Other Web3 wallets compatible with the blockchain your parrot NFTs are minted on. Make sure your wallet is properly set up and funded before attempting to mint your NFT." 
    },
    { 
      question: "What can I do if I encounter an issue during the minting process?", 
      answer: "If you run into any trouble while minting, follow these stepsCheck your wallet Ensure it’s properly connected, and you have sufficient funds to cover the minting fee and gas Browser issues Try refreshing your browser or switching to another one like Chrome or Brave." 
    },
    { 
      question: "How can I stay updated with the latest news?", 
      answer: "Stay in the loop by Following us on Twitter for real-time updates and announcements Joining the Discord for insider information and discussions with the community We frequently share news about upcoming drops, events, and airdrops!" 
    },
  ];

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="full-screen-section" id="faq">
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1 }}
        className="faq"
      >
        <h2 className="faq-title">FAQs</h2>
        <hr className="faq-divider" />
        <ul className="faq-list">
          {faqs.map((faq, index) => (
            <li key={index} className="faq-item">
              <div className="faq-question" onClick={() => toggleAnswer(index)}>
                {faq.question}
                {openIndex === index ? (
                  <FaChevronUp className="faq-icon" />
                ) : (
                  <FaChevronDown className="faq-icon" />
                )}
              </div>
              {openIndex === index && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="faq-answer"
                >
                  <p>{faq.answer}</p>
                </motion.div>
              )}
              <hr className="faq-item-divider" />
            </li>
          ))}
        </ul>
      </motion.div>
    </div>
  );
};

export default Faq;
