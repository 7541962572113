import React from 'react';
import './FixedButton.css';

const FixedButton = () => {
  const handleButtonClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button className="fixed-button" onClick={handleButtonClick}>
      <img src="/fb.png" alt="Home" />
    </button>
  );
};

export default FixedButton;
