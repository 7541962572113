import React from "react";
import { Link } from "react-scroll";
import "./Navbar.css";

const Navbar = () => {
  return (
    <nav className="navbar">
      {/* Logo Section */}
      <div className="navbar-logo">
        <Link to="hero" smooth={true} duration={500}>
          <img 
            src="/logo.png"
            alt="Logo"
            className="h-8 w-8 cursor-pointer"
          />
        </Link>
      </div>

      {/* Navigation Links */}
      <ul className="navbar-links flex flex-row items-center gap-5 text-2xl text-powderblue-100 font-bungee">
        <li>
          <Link to="story" smooth={true} offset={-50} duration={500}>
            About
          </Link>
        </li>
        <li>
          <Link to="advantages" smooth={true} offset={-50} duration={500}>
            Status
          </Link>
        </li>
        <li>
          <Link to="join-flock" smooth={true} offset={-50} duration={500}>
            Community
          </Link>
        </li>
        <li>
          <Link to="faq" smooth={true} offset={-50} duration={500}>
            FAQs
          </Link>
        </li>
        <li>
          <Link to="contact" smooth={true} offset={-50} duration={500}>
            Contact
          </Link>
        </li>
        <li>
  <button className="buy-now">
    Coming soon
  </button>
</li>

      </ul>
    </nav>
  );
};

export default Navbar;
