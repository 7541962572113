import React from "react";
import { motion } from "framer-motion";
import "./JoinFlock.css";

const JoinFlock = () => {
  return (
    <motion.div
      id="join-flock"
      initial={{ x: -100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 1 }}
      className="join-flock"
    >
      {/* Sliding char.png image */}
      <motion.div
        className="char-image-container"
        initial={{ x: "-100%" }} // Starts off-screen to the left
        animate={{ x: 0 }} // Slides in to its position
        transition={{ duration: 1.2, ease: "easeOut" }}
      >
        <img src="/char.png" alt="Character" className="char-image" />
      </motion.div>

      {/* Existing title background */}
      <div className="join-flock-title-bg"></div>

      {/* Description */}
      <p className="join-flock-description">
        In Ara’s Canopy, the heart of the town lies in its community—the vibrant flock of NFT holders and enthusiasts. Here, every parrot has a voice, and together, we shape the future of this mystical cyberpunk town nestled in the Verdant Veil.
      </p>

      {/* OpenSea button */}
      <a href="https://opensea.io/" className="join-flock-link">
        <div className="join-flock-button-container">
          <img className="join-flock-gp" src="/cs.png" alt="Background" />
        </div>
      </a>

      {/* Social buttons */}
      <div className="social-buttons">
  <a
    href="https://discord.gg/J2j9bZAm"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src="/Subtract.png" alt="Discord" className="social-icon" />
  </a>
  <a
    href="https://x.com/parrottownNFT"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src="/tw.png" alt="Twitter" className="social-icon" />
  </a>
</div>

    </motion.div>
  );
};

export default JoinFlock;
