import React, { useState } from "react";
import { motion } from "framer-motion";
import "./story.css";

const Story = () => {
  const [storyIndex, setStoryIndex] = useState(0);

  const stories = [
    {
      title: "Unveiling Story",
      subtitle: "of Ara's Canopy!",
      text: [
        "In the cold, metallic confines of the laboratory, freedom felt like a fantasy. Distant, unreachable, and nearly forgotten. The walls, smooth and unyielding, were illuminated by harsh fluorescent lights that buzzed faintly, echoing in the sterile silence. Rows of cages lined the room, each one a grim prison housing brilliant creatures, their once-vibrant plumage dulled by despair. But one bird had not succumbed to hopelessness. Ara, a bold and cunning Macaw, had been planning this moment for weeks.",
        "When the alarms blared, their shrill wails shattered the heavy silence. The corridors were drenched in the pulsing glow of red warning lights, giving everything a strange, otherworldly feel. Ara sprang into action, gripping the lock with her beak, twisting it just as she had practiced. The click of freedom filled her heart with fire. Behind her, the other parrots followed, their wings a kaleidoscope of color against the clinical white of the lab.",
        "Ahead lay a steel door, its edges glowing faintly with the light of the outside world. It was the threshold to the unknown, a gateway to the freedom they had longed for. Ara led the charge, her wings slicing through the air as hope surged through her being. This wasn’t just an escape—it was a rebellion, a chance to reclaim their future.",
      ],
      backgroundImage: "/st1.png",
    },
    {
      title: "Unveiling Story",
      subtitle: "OF ARA'S CANOPY!",
      text: [
        "Through endless skies, their wings carried them forward, beating with determination and hope despite the weight of exhaustion. The vast expanse of the world stretched below them—rolling hills, jagged mountains, and shadowy valleys. But it was the uncertainty that hung heaviest in their hearts. Were they truly free, or was another trap waiting just beyond the horizon? Yet, they pressed on, led by Ara, whose unwavering resolve inspired them to keep flying.",
        "Then, like a vision out of a dream, the Verdant Veil appeared. Rising abruptly from the barren lands they had crossed, the dense forest was unlike anything they had ever seen. A lush, emerald canopy that seemed to stretch endlessly, its thick foliage almost glowing with vitality. Ara paused mid-flight, her sharp eyes scanning the horizon. She let out a call, strong and clear, guiding her flock toward this unexpected oasis.",
        "As they descended, the air grew warmer, softer, almost welcoming. Ara landed on a sturdy branch, her claws gripping the bark, and looked around. The forest hummed with a quiet magic, its presence calming the panic that had gripped them for so long. Her flock gathered around her, their feathers rustling in the stillness. For the first time, Ara felt her chest loosen, the weight of fear lifting ever so slightly. 'This is it,' she murmured.",
      ],
      backgroundImage: "/st2.png",
    },
    {
      title: "Unveiling Story",
      subtitle: "of Ara's Canopy!",
      text: [
        "In the heart of the Verdant Veil, surrounded by the towering, ancient trees, the flock set to work. This was more than just building homes—it was about reclaiming their lives, creating something enduring from the ashes of their captivity. Under Ara’s guidance, they wove their future into the fabric of the forest, turning its natural beauty into a sanctuary unlike any other.",
        "The trees themselves seemed to welcome their presence, their gnarled branches providing sturdy foundations for nests and pathways. The flock worked tirelessly, their movements a symphony of determination and unity. Some gathered twigs, leaves, and moss, while others used their augmented claws and beaks to carve intricate designs into the bark, blending technology with nature in a way that felt both organic and new.",
        "As the day faded and the canopy glowed with the soft hues of dusk, Ara looked around at what they had accomplished. Ara’s Canopy wasn’t just a village—it was a symbol. It was proof that even after everything they had endured, they could thrive. Here, under the protective embrace of the Verdant Veil, they weren’t just surviving—they were building a life where they could truly be free."
      ],
      backgroundImage: "/st3.png",
    }
  ];

  const handlePreviousStory = () => {
    setStoryIndex((prevIndex) => (prevIndex - 1 + stories.length) % stories.length);
  };

  const handleNextStory = () => {
    setStoryIndex((prevIndex) => (prevIndex + 1) % stories.length);
  };

  return (
    <motion.div
      id="story"
      initial={{ x: "-100%", opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 1 }}
      className={`story ${
        storyIndex === 1 ? "story-variant" : storyIndex === 2 ? "story-st3" : ""
      }`}
      style={{ backgroundImage: `url(${stories[storyIndex].backgroundImage})` }}
    >
      <div className="story-container">
        <img
          src="/left.png"
          alt="Previous"
          className="nav-button left"
          onClick={handlePreviousStory}
        />
        <img
          src="/Right.png"
          alt="Next"
          className="nav-button right"
          onClick={handleNextStory}
        />
        <motion.div
          className="story-title"
          initial={{ x: "100%", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <span className="bold">{stories[storyIndex].title}</span>
          <br />
          <span className="light">{stories[storyIndex].subtitle}</span>
        </motion.div>
        <motion.div
          className="story-text"
          initial={{ x: "100%", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {stories[storyIndex].text.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Story;
