import React, { useEffect } from 'react';
import './Advantages.css';

const Advantages = () => {
  const advantages = [
    {
      number: "01",
      title: "THE POWER OF AUGMENTATIONS",
      content: [
        "Why settle for just wings, when you can have wings powered by the finest cyber-tech Ara could steal?",
        "Each NFT parrot comes with its own unique set of cyberpunk enhancements. From lightning-fast reflexes to night-vision goggles, these modifications aren't just for show—they're your ticket to exclusive events and rare story content in the Canopy. The more advanced the augmentation, the greater the access!"
      ]
    },
    {
      number: "02",
      title: "FLOCK MEMBERSHIP",
      content: [
        "Once a part of Ara's Canopy, always a part of Ara's Canopy.",
        "As an NFT holder, you become part of an exclusive community. You'll receive:",
        "Special Event Invites:",
        "Get VIP access to both virtual and physical meet-ups, including AR/VR experiences that bring the world of Ara's Canopy to life.",
        "Story Access:",
        "Unlock hidden chapters of the evolving parrot story. Who knows? Your parrot might just be the hero of the next saga."
      ]
    },
    {
      number: "03",
      title: "SECRET UPDATES AND HIDDEN TECH!",
      content: [
        "The forest is full of mysteries... only the most trusted parrots know where to find them.",
        "Being an NFT owner grants you clearance to periodic drops of hidden technology. These digital enhancements, exclusive to the Ara's Canopy community, are usable within your NFT for new, visually striking traits or even real-world bonuses tied to our future drops."
      ]
    },
    {
      number: "04",
      title: "GOVERNANCE AND DECISION-MAKING!",
      content: [
        "The flock flies as one. The future of the Canopy is in your feathers!",
        "Each parrot NFT comes with voting rights in key decisions for the future of the project. Do we venture into new digital forests? Do we enhance the cyber augments? Holders will have a say in the evolution of the town and its lore."
      ]
    },
    {
      number: "05",
      title: "MERCH PRIVILEGES",
      content: [
        "Exclusive Merch, wear your feathers proudly!",
        "As a parrot owner, you'll have access to limited-edition physical and digital merchandise. Whether it's stylish cyberpunk parrot gear or exclusive collectibles, flaunt your status in both the digital and real world."
      ]
    },
    {
      number: "06",
      title: "AIRDROPS AND SURPRISES!",
      content: [
        "Expect the unexpected in Ara's Canopy!",
        "The Verdant Veil is full of secrets, and as an NFT holder, you can expect periodic airdrops of exclusive content, upgrades, or even new parrots. From new cybernetic augmentations to exclusive art, you never know what's waiting for you in your nest."
      ]
    }
  ];
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    const elements = document.querySelectorAll('.advantage-item, .header-content, .footer-quote');
    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  return (
    <div id="advantages" className="advantages-container">
      <div className="bg-overlay"></div>
      <div className="advantages-content">
        {/* Top connection line */}
        <div className="top-connection">
          <div className="connection-line"></div>
          <div className="connection-dot"></div>
        </div>
        

        {/* Header section */}
        <div className="header-connection">
          <div className="header-content">
            <h3>CLEARANCE STATUS</h3>
            <div className="horizontal-line below-top"></div>
            <p>Welcome to the Flight Academy, where every parrot in Ara's Canopy earns its wings! Your clearance status determines your rank within the flock, and with higher ranks come special privileges.</p>
          </div>
        </div>

        {/* Main content section */}
        <div className="main-content">
          <h2 className="main-title">ADVANTAGES OF BUYING THIS NFT!</h2>
          <p className="main-subtitle">
            Get ready to take flight as we reveal why owning an Ara's Canopy NFT isn't just a purchase; it's an adventure into the skies of the verdant veil.
          </p>
           {/*line*/}
          {/* Advantages list */}
          <div className="advantages-list">
            {advantages.map(({ number, title, content }) => (
              <div key={number} className="advantage-item">
                <h3>NUMBER {number} - {title}</h3>
                {content.map((text, index) => (
                  <p key={index}>{text}</p>
                ))}
              </div>
            ))}
          </div>
          {/*line*/}
          {/* Footer quote */}
          <div className="horizontal-line above-bottom"></div>
          <div className="footer-quote">
            <p>"IN ARA'S CANOPY, THE SKY ISN'T THE LIMIT—IT'S ONLY THE BEGINNING. SO SPREAD YOUR WINGS, GEAR UP, AND TAKE YOUR RIGHTFUL PLACE AMONG THE STARS."</p>
          </div>

          {/* Bottom connection line */}
          <div className="bottom-connection">
            <div className="connection-line"></div>
            <div className="connection-dot"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantages;